export const env = {
    production: true,
    apiKey: "AIzaSyDne5HA0t7VhgTe3-1ACQi0FfnwOwQnjM8",
    authDomain: "dotachieve-79283.firebaseapp.com",
    databaseURL: "https://dotachieve-79283.firebaseio.com",
    projectId: "dotachieve-79283",
    storageBucket: "dotachieve-79283.appspot.com",
    messagingSenderId: "786799954979",
    appId: "1:786799954979:web:9104234eb0d0efff4af596",
    measurementId: "G-SYHJSSFZ0V"
};
