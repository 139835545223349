import { Component } from '@angular/core';
import { OutlineComponent } from '../outline.component';

@Component({
  selector: 'outline-profile',
  templateUrl: './outline-profile.html',
  styleUrls: ['./outline-profile.scss']
})
export class OutlineProfileComponent extends OutlineComponent {
  constructor() {
    super('.outline-profile');
  }
}
