<div class="contact-section" #container>
  <h2 class="contact-header">{{title}}</h2>
  <app-plocket #plocket class="plocket"></app-plocket>
  <div class="contact-message" #contactMessage>
      <p>Tell me a little bit about you and your business. Feel free to say as much as you want!</p>
      <form 
      class="form-contact" 
      (ngSubmit)="onSubmit()" #form="ngForm">
        <div class="form-control-group">
          <input
            class="form-control"
            [ngClass]="{submitted: form.submitted}"
            type="text"
            name="name"
            autocomplete="off"
            required
            ngModel
            #name="ngModel">
          <label for="name" class="form-control-label" [ngClass]="{empty: !form.value.name}">Name</label>
        </div>
        <p class="error-message"
          [@errorMessage]="name.invalid && form.submitted ? 'bad' : 'good'">Don't be shy! How can I call you?</p>
        <div class="form-control-group">
          <input
            class="form-control"
            [ngClass]="{submitted: form.submitted}"
            type="text" 
            name="email"
            autocomplete="off"
            required
            email
            ngModel
            #email="ngModel">
            <label for="email" class="form-control-label" [ngClass]="{empty: !form.value.email}">Email</label>
        </div>
        <p class="error-message"
          [@errorMessage]="email.invalid && form.submitted ? 'bad' : 'good'">It will be easier to contact you if I know your name :D</p>
        <div class="form-control-group">
          <textarea
            class="form-control"
            [ngClass]="{submitted: form.submitted}"
            name="message" 
            id="idMessage"
            cols="30"
            rows="6"
            autocomplete="off"
            required
            ngModel
            #message="ngModel"></textarea>
            <label for="message" class="form-control-label" [ngClass]="{empty: !form.value.message}">Message</label>
        </div>
        <p 
          class="error-message"
          [@errorMessage]="message.invalid && form.submitted ? 'bad' : 'good'"
          >Oops, you forgot something.</p>
          <p class="note">The information you insert here are encrypted end-to-end and I'm the only one with access to it!</p>
        <button 
          type="submit"
          class="btn-submit">SEND</button>
      </form>
  </div>
  <p 
    class="contact-message-handler message-sent"
    [ngClass]="{'open': isSent}">
      Your message was sent! I'll respond as soon as possible!
      <br/>
      Meanwhile, take a look at my social medias!
  </p>
  <p 
    class="contact-message-handler message-fail"
    [ngClass]="{'open': messageFail}">Mayday! Something went wrong! You can try again or contact me through any of the links below.</p>
</div>