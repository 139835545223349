<svg viewBox="0 0 39.32 92.2">
  <g class="cls-1">
    <g id="Layer_1" data-name="Layer 1">
      <g id="plocket">
        <g id="plocket-group">
          <g id="liquid-fuel">
            <path class="cls-2" d="M54.2,36.6c.5.1.9.1,1.3.2a11.31,11.31,0,0,1,1.2.2V78.6s-.1,1.4-.9,1.4H50.2V36.3a21.75,21.75,0,0,1,2.5.1C53.2,36.5,53.7,36.5,54.2,36.6Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-3" d="M55.4,36.8l.1-11.1,1.1.1V37A6.91,6.91,0,0,0,55.4,36.8Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-2" d="M56.7,20.1v4.6s-3.9-.4-6.5-.4V20.1h6.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-4" d="M54.8,11a39.58,39.58,0,0,1,1.3,9.1H50.2V7l2.3-.9A14,14,0,0,1,54.8,11Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-5" d="M55.6,25.7l-.1,11.1a6.88,6.88,0,0,1-1.3-.2c0-.2.5-3.1.5-3.2s0-.6,0-.6l-.1-7.2Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-3" d="M54.6,25.6l.1,7.2s.1.5,0,.6a26.94,26.94,0,0,0-.5,3.2,7.72,7.72,0,0,1-1.5-.2V25.6l.8-.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-5" d="M52.7,28v8.4a20.08,20.08,0,0,0-2.5-.1V26.7h2.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-6" d="M52.5,6.1,50.2,7V3.6A11.46,11.46,0,0,1,52.5,6.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-7" d="M50.2,24.3c2.5,0,6.5.4,6.5.4v1l-1.1-.1-1-.1-1.2-.1-.8.1v1.1H50.1l.1-2.3Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-5" d="M50.2,20.1v4.2c-2.5,0-6.5.4-6.5.4V20.1h6.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-8" d="M48,6.1a11.46,11.46,0,0,1,2.3-2.5V7Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-3" d="M48.9,36.3l.1-9.6h1.3v9.6Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-9" d="M49,25.8v1l-.1,9.6h-.7V25.6h.3Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-5" d="M48.2,25.6V36.5c-.5,0-1,.1-1.5.1v-11Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-5" d="M46.7,36.5c.5,0,1-.1,1.5-.1h2.1V80H44.7c-.8,0-.9-1.4-.9-1.4V37s1-.2,2.5-.4A.6.6,0,0,0,46.7,36.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-9" d="M46.7,25.6V36.5h-.4s-.3-.5-.5-.9a1.55,1.55,0,0,1-.2-.6l.2-9.4Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-5" d="M46.3,36.6c-1.5.2-2.5.4-2.5.4V25.8l2-.1-.2,9.4a1.55,1.55,0,0,0,.2.6A5.76,5.76,0,0,0,46.3,36.6Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-10" d="M43.8,24.7s3.9-.4,6.5-.4v2.4H49v-1l-.5-.2h-.3l-1.5.1h-.9l-2,.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-11" d="M50.2,20.1H44.3A39.58,39.58,0,0,1,45.6,11,16,16,0,0,1,48,6.1l2.2.9Z" transform="translate(-30.6 -3.6)"/>
          </g>
          <g id="solid-booster2">
            <path class="cls-12" d="M62.7,93.6l.8,2s-2.2.2-3,.2V90.4h1.8v.2h.5s.3,0,.4.9.5,2.1.1,2.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-13" d="M63.4,89.1v1.1h-1v.1H60.6V89C61.3,89,63.4,89.1,63.4,89.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-14" d="M60.6,89v-.4h0c.7,0,2.8.1,2.8.1v.5A17,17,0,0,0,60.6,89Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-15" d="M63.4,88.3v.4s-2.1-.1-2.8-.1h0v-.5C61.2,88.1,63.4,88.3,63.4,88.3Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-13" d="M63.4,87.5v.8s-2.2-.2-2.8-.2v-.7A21,21,0,0,1,63.4,87.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-14" d="M63.4,87.1v.4s-2.2-.2-2.8-.2v-.4C61.2,86.9,63.4,87.1,63.4,87.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-15" d="M63.4,86.6v.5s-2.2-.2-2.8-.2v-.5C61.2,86.4,63.4,86.6,63.4,86.6Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-13" d="M63.4,68.1V86.6s-2.2-.2-2.8-.2V67.9C61.2,67.9,63.4,68.1,63.4,68.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-15" d="M63.4,67.6V68s-2.2-.1-2.8-.1v-.5C61.2,67.5,63.4,67.6,63.4,67.6Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-13" d="M63.4,55.1V67.6s-2.2-.2-2.8-.2V55C61.2,55,63.4,55.1,63.4,55.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-15" d="M63.4,54.7v.4s-2.2-.1-2.8-.1v-.5C61.2,54.5,63.4,54.7,63.4,54.7Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-13" d="M63.4,42.1V54.6s-2.2-.2-2.8-.2V42h.1C61.2,42,62.9,42.1,63.4,42.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-15" d="M63.4,41.7v.4c-.5,0-2.2-.1-2.7-.1h-.1v-.5h.1C61.2,41.5,62.9,41.7,63.4,41.7Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-13" d="M63.4,34.5v7.2c-.5,0-2.2-.2-2.7-.2h-.1V34.3C61.2,34.4,63.4,34.5,63.4,34.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-15" d="M63.4,34.1v.4s-2.2-.1-2.8-.1v-.5C61.2,33.9,63.4,34.1,63.4,34.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-13" d="M63.4,26.9V34s-2.2-.2-2.8-.2V26.5A27.71,27.71,0,0,1,63.4,26.9Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-16" d="M62.1,23.9a22.28,22.28,0,0,1,1.3,3,16.86,16.86,0,0,0-2.8-.3V23.9h1.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-17" d="M61.8,23.9H60.5V20.7s.6.1.8,1.2A16.28,16.28,0,0,0,61.8,23.9Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-18" d="M60.6,90.4v5.4c-.7,0-3-.2-3-.2l.8-2h-.6c-.4,0-.1-1.2.1-2.1s.4-.9.4-.9h.5v-.2Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-19" d="M60.6,89v1.4H58.8v-.1h-1v-1A14.72,14.72,0,0,1,60.6,89Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-20" d="M60.6,89c-.7,0-2.8.2-2.8.2v-.5s2.1-.2,2.8-.2Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-21" d="M60.6,88.1v.5c-.7,0-2.8.2-2.8.2v-.5S59.9,88.1,60.6,88.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-19" d="M60.6,87.3V88c-.7,0-2.8.2-2.8.2v-.8C57.8,87.5,59.9,87.3,60.6,87.3Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-20" d="M60.6,86.9v.4c-.7,0-2.8.2-2.8.2V87S60,86.9,60.6,86.9Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-21" d="M60.6,86.4v.5c-.6,0-2.8.1-2.8.1v-.4S60,86.4,60.6,86.4Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-19" d="M60.6,67.9V86.4c-.6,0-2.8.2-2.8.2V68.1S60,67.9,60.6,67.9Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-21" d="M60.6,67.5V68c-.6,0-2.8.1-2.8.1v-.4A18,18,0,0,1,60.6,67.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-19" d="M60.6,55V67.5c-.6,0-2.8.2-2.8.2V55.2A18,18,0,0,1,60.6,55Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-21" d="M60.6,54.5V55c-.6,0-2.8.1-2.8.1v-.4S60,54.5,60.6,54.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-19" d="M60.6,42V54.5c-.6,0-2.8.2-2.8.2V42.2c.5,0,2.1-.1,2.7-.1h.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-21" d="M60.6,41.5V42h-.1c-.5,0-2.2.1-2.7.1v-.4c.5,0,2.2-.2,2.8-.2Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-19" d="M60.6,34.4v7.2h-.1a24.52,24.52,0,0,0-2.7.2V34.6A18,18,0,0,1,60.6,34.4Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-21" d="M60.6,33.9v.5c-.6,0-2.8.1-2.8.1v-.4S60,33.9,60.6,33.9Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-19" d="M60.6,26.6v7.3c-.6,0-2.8.2-2.8.2V27A7.86,7.86,0,0,1,60.6,26.6Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-19" d="M60.6,23.9v2.7a15.32,15.32,0,0,0-2.8.3,22.28,22.28,0,0,1,1.3-3h1.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-22" d="M60.6,20.8V24H59.3a9.92,9.92,0,0,0,.4-1.9C60,20.9,60.6,20.8,60.6,20.8Z" transform="translate(-30.6 -3.6)"/>
          </g>
          <g id="solid-booster1">
            <path class="cls-12" d="M42,93.6l.8,2s-2.2.2-2.9.2V90.4h1.8v.2h.5s.3,0,.4.9.5,2.1.1,2.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-13" d="M42.7,89.1v1.1h-1v.1H39.9V89C40.7,89,42.7,89.1,42.7,89.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-14" d="M39.9,89v-.4h0c.7,0,2.8.1,2.8.1v.5A16,16,0,0,0,39.9,89Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-15" d="M42.7,88.3v.4s-2.1-.1-2.8-.1h0v-.5C40.6,88.1,42.7,88.3,42.7,88.3Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-13" d="M42.7,87.5v.8s-2.1-.2-2.8-.2v-.7A20.24,20.24,0,0,1,42.7,87.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-14" d="M42.7,87.1v.4s-2.1-.2-2.8-.2v-.4C40.5,86.9,42.7,87.1,42.7,87.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-15" d="M42.7,86.6v.5s-2.2-.2-2.8-.2v-.5C40.5,86.4,42.7,86.6,42.7,86.6Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-13" d="M42.7,68.1V86.6s-2.2-.2-2.8-.2V67.9C40.5,67.9,42.7,68.1,42.7,68.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-15" d="M42.7,67.6V68s-2.2-.1-2.8-.1v-.5C40.5,67.5,42.7,67.6,42.7,67.6Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-13" d="M42.7,55.1V67.6s-2.2-.2-2.8-.2V55C40.5,55,42.7,55.1,42.7,55.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-15" d="M42.7,54.7v.4s-2.2-.1-2.8-.1v-.5C40.5,54.5,42.7,54.7,42.7,54.7Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-13" d="M42.7,42.1V54.6s-2.2-.2-2.8-.2V42H40C40.6,42,42.2,42.1,42.7,42.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-15" d="M42.7,41.7v.4c-.5,0-2.2-.1-2.7-.1h-.1v-.5H40C40.5,41.5,42.2,41.7,42.7,41.7Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-13" d="M42.7,34.5v7.2c-.5,0-2.2-.2-2.7-.2h-.1V34.3C40.5,34.4,42.7,34.5,42.7,34.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-15" d="M42.7,34.1v.4s-2.2-.1-2.8-.1v-.5C40.5,33.9,42.7,34.1,42.7,34.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-13" d="M42.7,26.9V34s-2.2-.2-2.8-.2V26.5A23.25,23.25,0,0,1,42.7,26.9Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-16" d="M41.5,23.9a22.28,22.28,0,0,1,1.3,3,16.86,16.86,0,0,0-2.8-.3V23.9h1.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-17" d="M41.2,23.9H39.9V20.7s.6.1.8,1.2A11.79,11.79,0,0,0,41.2,23.9Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-18" d="M39.9,90.4v5.4c-.7,0-3-.2-3-.2l.8-2h-.6c-.4,0-.1-1.2.1-2.1s.4-.9.4-.9h.5v-.2Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-19" d="M39.9,89v1.4H38.1v-.1h-1v-1A14.72,14.72,0,0,1,39.9,89Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-20" d="M39.9,89c-.7,0-2.8.2-2.8.2v-.5s2.1-.2,2.8-.2Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-21" d="M39.9,88.1v.5c-.7,0-2.8.2-2.8.2v-.5S39.3,88.1,39.9,88.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-19" d="M39.9,87.3V88c-.7,0-2.8.2-2.8.2v-.8C37.1,87.5,39.3,87.3,39.9,87.3Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-20" d="M39.9,86.9v.4c-.7,0-2.8.2-2.8.2V87S39.3,86.9,39.9,86.9Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-21" d="M39.9,86.4v.5c-.6,0-2.8.1-2.8.1v-.4S39.3,86.4,39.9,86.4Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-19" d="M39.9,67.9V86.4c-.6,0-2.8.2-2.8.2V68.1S39.3,67.9,39.9,67.9Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-21" d="M39.9,67.5V68c-.6,0-2.8.1-2.8.1v-.4A18,18,0,0,1,39.9,67.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-19" d="M39.9,55V67.5c-.6,0-2.8.2-2.8.2V55.2A18,18,0,0,1,39.9,55Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-21" d="M39.9,54.5V55c-.6,0-2.8.1-2.8.1v-.4S39.3,54.5,39.9,54.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-19" d="M39.9,42V54.5c-.6,0-2.8.2-2.8.2V42.2c.5,0,2.2-.1,2.7-.1h.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-21" d="M39.9,41.5V42h-.1c-.5,0-2.2.1-2.7.1v-.4c.5,0,2.2-.2,2.8-.2Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-19" d="M39.9,34.4v7.2h-.1a24.52,24.52,0,0,0-2.7.2V34.6A18,18,0,0,1,39.9,34.4Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-21" d="M39.9,33.9v.5c-.6,0-2.8.1-2.8.1v-.4S39.3,33.9,39.9,33.9Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-19" d="M39.9,26.6v7.3c-.6,0-2.8.2-2.8.2V27A8.42,8.42,0,0,1,39.9,26.6Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-19" d="M39.9,23.9v2.7a15.32,15.32,0,0,0-2.8.3,22.28,22.28,0,0,1,1.3-3h1.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-22" d="M39.9,20.8V24H38.6a9.92,9.92,0,0,0,.4-1.9C39.3,20.9,39.9,20.8,39.9,20.8Z" transform="translate(-30.6 -3.6)"/>
          </g>
          <g id="shuttle">
            <path class="cls-23" d="M69.8,78.9c.1,1.3.2,2.9,0,3,0,0-1,.3-2.3.6V80.7c0-.7-.8-.8-.8-.8H59c-1.2,0-1.3.9-1.3.9v3.8a9,9,0,0,1-1.5.3l.1-7.2-.6-1.5h-.8V44.5c.1.2,1.8,10.8,2.6,15a4.33,4.33,0,0,0,.1.5c.1.6.2,1.1.2,1.2v.3c.4,2.1,1.1,5.3,1.4,5.5.4.4,8,7.9,8.6,8.6S69.8,78.9,69.8,78.9Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-24" d="M69.7,77.5a9.51,9.51,0,0,0,.1,1.4s-1.5-2.5-2-3.3-8.2-8.3-8.6-8.6-.9-3.4-1.4-5.5A31.14,31.14,0,0,0,59,65.4c.4.5,8.9,8.8,9.2,9.3A9.59,9.59,0,0,1,69.7,77.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-25" d="M67.5,80.7v1.8c-2.8.6-7.4,1.6-9.8,2.1V80.8s0-.9,1.3-.9h7.7S67.5,80,67.5,80.7Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-26" d="M55.7,76.2l.6,1.5-.1,7.2h-.1s.3,1.9.1,1.9H54.9V85.4l-2.7-.1V83.1h-.8v-7H55l.7.1Z" transform="translate(-30.6 -3.6)"/>
            <polygon class="cls-26" points="24.3 40.9 24.3 72.6 20.7 72.6 19.6 72.6 19.6 40.9 24.3 40.9"/>
            <path class="cls-27" d="M54.9,44.5H50.2V38.1h1.2v-1H50.2V29.6s2,.3,3,3.1a33.82,33.82,0,0,1,1.5,6.1C54.9,39.9,54.8,44.2,54.9,44.5Zm-.4-4.3-.2-1.3-.4.3.3,1Zm-1-1.3.4-.5-1.7-1.2v.9Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-28" d="M54.3,87.9s.7,1.2.6,1.7-1,.9-1.8.9a4.64,4.64,0,0,1-1.8-.7H52V87.4h-.6V85.6h.5v-.4h.2l2.7.1v2.5h-.5Z" transform="translate(-30.6 -3.6)"/>
            <polygon class="cls-8" points="23.7 35.3 23.9 36.6 23.6 36.6 23.3 35.7 23.7 35.3"/>
            <polygon class="cls-8" points="23.3 34.9 22.9 35.3 21.5 34.6 21.5 33.7 23.3 34.9"/>
            <polygon class="cls-29" points="21.5 79.6 21.5 81.8 21.2 81.8 21.2 82.1 20.8 82.1 20.8 83.9 21.4 83.9 21.4 86.3 20.8 86.3 20.5 86.3 20.5 89.8 19.6 89.8 19.6 72.6 20.7 72.6 20.7 79.6 21.5 79.6"/>
            <rect class="cls-8" x="19.6" y="33.5" width="1.2" height="1"/>
            <polygon class="cls-25" points="19.6 72.6 19.6 89.8 18.8 89.8 18.8 86.3 18.5 86.3 17.9 86.3 17.9 83.9 18.5 83.9 18.5 82.1 18.1 82.1 18.1 81.8 17.8 81.8 17.8 79.6 18.6 79.6 18.6 72.6 19.6 72.6"/>
            <polygon class="cls-30" points="19.6 40.9 19.6 72.6 18.6 72.6 15 72.6 15 40.9 19.6 40.9"/>
            <path class="cls-30" d="M50.2,38.1v6.4H45.5c.1-.3,0-4.6.2-5.6a26.81,26.81,0,0,1,1.5-6.1c1-2.8,3-3.1,3-3.1v7.5H49v1h1.2Zm-1.8.1v-.9l-1.7,1.2.4.5Zm-2.1,2,.3-1-.4-.3L46,40.2Z" transform="translate(-30.6 -3.6)"/>
            <rect class="cls-8" x="18.5" y="33.5" width="1.2" height="1"/>
            <path class="cls-30" d="M49.2,76.2v7h-.8v2.2l-2.7.1v1.4H44.4c-.2,0,.1-1.9.1-1.9h-.1l-.1-7.2.6-1.5h.8l3.5-.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-31" d="M48.5,89.9h.6a4,4,0,0,1-1.8.7,2.13,2.13,0,0,1-1.8-.9c-.1-.5.6-1.7.6-1.7h-.5V85.5l2.7-.1h.2v.4H49v1.8h-.6l.1,2.3Z" transform="translate(-30.6 -3.6)"/>
            <polygon class="cls-32" points="17.8 33.7 17.8 34.6 16.4 35.3 16 34.9 17.8 33.7"/>
            <polygon class="cls-32" points="16 35.7 15.7 36.6 15.4 36.6 15.6 35.3 16 35.7"/>
            <path class="cls-26" d="M45.6,44.5V76.2h-.8l-.6,1.5.1,7.2c-.2,0-.8-.2-1.5-.3V80.8s0-.9-1.3-.9H33.7a.81.81,0,0,0-.7.8v1.8a9.51,9.51,0,0,1-2.3-.6,27.75,27.75,0,0,1,0-3s1.5-2.5,2-3.3,8.2-8.3,8.6-8.6.9-3.4,1.4-5.5v-.3c0-.1.1-.5.2-1V60a1.75,1.75,0,0,0,.1-.5C43.8,55.2,45.5,44.7,45.6,44.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-25" d="M42.8,80.8v3.8c-2.4-.5-7-1.5-9.8-2.1V80.7c0-.7.8-.8.8-.8h7.8C42.9,79.9,42.8,80.8,42.8,80.8Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-24" d="M42.6,61.5c-.4,2.1-1.1,5.3-1.4,5.5-.4.4-8,7.9-8.6,8.6s-2,3.3-2,3.3c0-.7.1-1.2.1-1.4a12.21,12.21,0,0,1,1.4-2.8c.3-.5,8.8-8.8,9.2-9.3A16.17,16.17,0,0,0,42.6,61.5Z" transform="translate(-30.6 -3.6)"/>
          </g>
        </g>
        <g id="plocket-group-2" data-name="plocket-group" class="cls-33">
          <g id="liquid-fuel-2" data-name="liquid-fuel">
            <path class="cls-34" d="M54.2,36.6c.5.1.9.1,1.3.2a11.31,11.31,0,0,1,1.2.2V78.6s-.1,1.4-.9,1.4H50.2V36.3a21.75,21.75,0,0,1,2.5.1C53.2,36.5,53.7,36.5,54.2,36.6Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M55.4,36.8l.1-11.1,1.1.1V37A6.91,6.91,0,0,0,55.4,36.8Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M56.7,20.1v4.6s-3.9-.4-6.5-.4V20.1h6.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M54.8,11a39.58,39.58,0,0,1,1.3,9.1H50.2V7l2.3-.9A14,14,0,0,1,54.8,11Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M55.6,25.7l-.1,11.1a6.88,6.88,0,0,1-1.3-.2c0-.2.5-3.1.5-3.2s0-.6,0-.6l-.1-7.2Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M54.6,25.6l.1,7.2s.1.5,0,.6a26.94,26.94,0,0,0-.5,3.2,7.72,7.72,0,0,1-1.5-.2V25.6l.8-.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M52.7,28v8.4a20.08,20.08,0,0,0-2.5-.1V26.7h2.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M52.5,6.1,50.2,7V3.6A11.46,11.46,0,0,1,52.5,6.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M50.2,24.3c2.5,0,6.5.4,6.5.4v1l-1.1-.1-1-.1-1.2-.1-.8.1v1.1H50.1l.1-2.3Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M50.2,20.1v4.2c-2.5,0-6.5.4-6.5.4V20.1h6.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M48,6.1a11.46,11.46,0,0,1,2.3-2.5V7Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M48.9,36.3l.1-9.6h1.3v9.6Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M49,25.8v1l-.1,9.6h-.7V25.6h.3Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M48.2,25.6V36.5c-.5,0-1,.1-1.5.1v-11Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M46.7,36.5c.5,0,1-.1,1.5-.1h2.1V80H44.7c-.8,0-.9-1.4-.9-1.4V37s1-.2,2.5-.4A.6.6,0,0,0,46.7,36.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M46.7,25.6V36.5h-.4s-.3-.5-.5-.9a1.55,1.55,0,0,1-.2-.6l.2-9.4Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M46.3,36.6c-1.5.2-2.5.4-2.5.4V25.8l2-.1-.2,9.4a1.55,1.55,0,0,0,.2.6A5.76,5.76,0,0,0,46.3,36.6Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M43.8,24.7s3.9-.4,6.5-.4v2.4H49v-1l-.5-.2h-.3l-1.5.1h-.9l-2,.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M50.2,20.1H44.3A39.58,39.58,0,0,1,45.6,11,16,16,0,0,1,48,6.1l2.2.9Z" transform="translate(-30.6 -3.6)"/>
          </g>
          <g id="solid-booster2-2" data-name="solid-booster2">
            <path class="cls-34" d="M62.7,93.6l.8,2s-2.2.2-3,.2V90.4h1.8v.2h.5s.3,0,.4.9.5,2.1.1,2.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M63.4,89.1v1.1h-1v.1H60.6V89C61.3,89,63.4,89.1,63.4,89.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M60.6,89v-.4h0c.7,0,2.8.1,2.8.1v.5A17,17,0,0,0,60.6,89Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M63.4,88.3v.4s-2.1-.1-2.8-.1h0v-.5C61.2,88.1,63.4,88.3,63.4,88.3Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M63.4,87.5v.8s-2.2-.2-2.8-.2v-.7A21,21,0,0,1,63.4,87.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M63.4,87.1v.4s-2.2-.2-2.8-.2v-.4C61.2,86.9,63.4,87.1,63.4,87.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M63.4,86.6v.5s-2.2-.2-2.8-.2v-.5C61.2,86.4,63.4,86.6,63.4,86.6Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M63.4,68.1V86.6s-2.2-.2-2.8-.2V67.9C61.2,67.9,63.4,68.1,63.4,68.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M63.4,67.6V68s-2.2-.1-2.8-.1v-.5C61.2,67.5,63.4,67.6,63.4,67.6Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M63.4,55.1V67.6s-2.2-.2-2.8-.2V55C61.2,55,63.4,55.1,63.4,55.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M63.4,54.7v.4s-2.2-.1-2.8-.1v-.5C61.2,54.5,63.4,54.7,63.4,54.7Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M63.4,42.1V54.6s-2.2-.2-2.8-.2V42h.1C61.2,42,62.9,42.1,63.4,42.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M63.4,41.7v.4c-.5,0-2.2-.1-2.7-.1h-.1v-.5h.1C61.2,41.5,62.9,41.7,63.4,41.7Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M63.4,34.5v7.2c-.5,0-2.2-.2-2.7-.2h-.1V34.3C61.2,34.4,63.4,34.5,63.4,34.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M63.4,34.1v.4s-2.2-.1-2.8-.1v-.5C61.2,33.9,63.4,34.1,63.4,34.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M63.4,26.9V34s-2.2-.2-2.8-.2V26.5A27.71,27.71,0,0,1,63.4,26.9Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M62.1,23.9a22.28,22.28,0,0,1,1.3,3,16.86,16.86,0,0,0-2.8-.3V23.9h1.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M61.8,23.9H60.5V20.7s.6.1.8,1.2A16.28,16.28,0,0,0,61.8,23.9Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M60.6,90.4v5.4c-.7,0-3-.2-3-.2l.8-2h-.6c-.4,0-.1-1.2.1-2.1s.4-.9.4-.9h.5v-.2Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M60.6,89v1.4H58.8v-.1h-1v-1A14.72,14.72,0,0,1,60.6,89Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M60.6,89c-.7,0-2.8.2-2.8.2v-.5s2.1-.2,2.8-.2Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M60.6,88.1v.5c-.7,0-2.8.2-2.8.2v-.5S59.9,88.1,60.6,88.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M60.6,87.3V88c-.7,0-2.8.2-2.8.2v-.8C57.8,87.5,59.9,87.3,60.6,87.3Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M60.6,86.9v.4c-.7,0-2.8.2-2.8.2V87S60,86.9,60.6,86.9Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M60.6,86.4v.5c-.6,0-2.8.1-2.8.1v-.4S60,86.4,60.6,86.4Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M60.6,67.9V86.4c-.6,0-2.8.2-2.8.2V68.1S60,67.9,60.6,67.9Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M60.6,67.5V68c-.6,0-2.8.1-2.8.1v-.4A18,18,0,0,1,60.6,67.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M60.6,55V67.5c-.6,0-2.8.2-2.8.2V55.2A18,18,0,0,1,60.6,55Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M60.6,54.5V55c-.6,0-2.8.1-2.8.1v-.4S60,54.5,60.6,54.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M60.6,42V54.5c-.6,0-2.8.2-2.8.2V42.2c.5,0,2.1-.1,2.7-.1h.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M60.6,41.5V42h-.1c-.5,0-2.2.1-2.7.1v-.4c.5,0,2.2-.2,2.8-.2Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M60.6,34.4v7.2h-.1a24.52,24.52,0,0,0-2.7.2V34.6A18,18,0,0,1,60.6,34.4Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M60.6,33.9v.5c-.6,0-2.8.1-2.8.1v-.4S60,33.9,60.6,33.9Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M60.6,26.6v7.3c-.6,0-2.8.2-2.8.2V27A7.86,7.86,0,0,1,60.6,26.6Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M60.6,23.9v2.7a15.32,15.32,0,0,0-2.8.3,22.28,22.28,0,0,1,1.3-3h1.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M60.6,20.8V24H59.3a9.92,9.92,0,0,0,.4-1.9C60,20.9,60.6,20.8,60.6,20.8Z" transform="translate(-30.6 -3.6)"/>
          </g>
          <g id="solid-booster1-2" data-name="solid-booster1">
            <path class="cls-34" d="M42,93.6l.8,2s-2.2.2-2.9.2V90.4h1.8v.2h.5s.3,0,.4.9.5,2.1.1,2.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M42.7,89.1v1.1h-1v.1H39.9V89C40.7,89,42.7,89.1,42.7,89.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M39.9,89v-.4h0c.7,0,2.8.1,2.8.1v.5A16,16,0,0,0,39.9,89Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M42.7,88.3v.4s-2.1-.1-2.8-.1h0v-.5C40.6,88.1,42.7,88.3,42.7,88.3Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M42.7,87.5v.8s-2.1-.2-2.8-.2v-.7A20.24,20.24,0,0,1,42.7,87.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M42.7,87.1v.4s-2.1-.2-2.8-.2v-.4C40.5,86.9,42.7,87.1,42.7,87.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M42.7,86.6v.5s-2.2-.2-2.8-.2v-.5C40.5,86.4,42.7,86.6,42.7,86.6Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M42.7,68.1V86.6s-2.2-.2-2.8-.2V67.9C40.5,67.9,42.7,68.1,42.7,68.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M42.7,67.6V68s-2.2-.1-2.8-.1v-.5C40.5,67.5,42.7,67.6,42.7,67.6Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M42.7,55.1V67.6s-2.2-.2-2.8-.2V55C40.5,55,42.7,55.1,42.7,55.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M42.7,54.7v.4s-2.2-.1-2.8-.1v-.5C40.5,54.5,42.7,54.7,42.7,54.7Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M42.7,42.1V54.6s-2.2-.2-2.8-.2V42H40C40.6,42,42.2,42.1,42.7,42.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M42.7,41.7v.4c-.5,0-2.2-.1-2.7-.1h-.1v-.5H40C40.5,41.5,42.2,41.7,42.7,41.7Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M42.7,34.5v7.2c-.5,0-2.2-.2-2.7-.2h-.1V34.3C40.5,34.4,42.7,34.5,42.7,34.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M42.7,34.1v.4s-2.2-.1-2.8-.1v-.5C40.5,33.9,42.7,34.1,42.7,34.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M42.7,26.9V34s-2.2-.2-2.8-.2V26.5A23.25,23.25,0,0,1,42.7,26.9Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M41.5,23.9a22.28,22.28,0,0,1,1.3,3,16.86,16.86,0,0,0-2.8-.3V23.9h1.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M41.2,23.9H39.9V20.7s.6.1.8,1.2A11.79,11.79,0,0,0,41.2,23.9Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M39.9,90.4v5.4c-.7,0-3-.2-3-.2l.8-2h-.6c-.4,0-.1-1.2.1-2.1s.4-.9.4-.9h.5v-.2Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M39.9,89v1.4H38.1v-.1h-1v-1A14.72,14.72,0,0,1,39.9,89Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M39.9,89c-.7,0-2.8.2-2.8.2v-.5s2.1-.2,2.8-.2Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M39.9,88.1v.5c-.7,0-2.8.2-2.8.2v-.5S39.3,88.1,39.9,88.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M39.9,87.3V88c-.7,0-2.8.2-2.8.2v-.8C37.1,87.5,39.3,87.3,39.9,87.3Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M39.9,86.9v.4c-.7,0-2.8.2-2.8.2V87S39.3,86.9,39.9,86.9Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M39.9,86.4v.5c-.6,0-2.8.1-2.8.1v-.4S39.3,86.4,39.9,86.4Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M39.9,67.9V86.4c-.6,0-2.8.2-2.8.2V68.1S39.3,67.9,39.9,67.9Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M39.9,67.5V68c-.6,0-2.8.1-2.8.1v-.4A18,18,0,0,1,39.9,67.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M39.9,55V67.5c-.6,0-2.8.2-2.8.2V55.2A18,18,0,0,1,39.9,55Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M39.9,54.5V55c-.6,0-2.8.1-2.8.1v-.4S39.3,54.5,39.9,54.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M39.9,42V54.5c-.6,0-2.8.2-2.8.2V42.2c.5,0,2.2-.1,2.7-.1h.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M39.9,41.5V42h-.1c-.5,0-2.2.1-2.7.1v-.4c.5,0,2.2-.2,2.8-.2Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M39.9,34.4v7.2h-.1a24.52,24.52,0,0,0-2.7.2V34.6A18,18,0,0,1,39.9,34.4Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M39.9,33.9v.5c-.6,0-2.8.1-2.8.1v-.4S39.3,33.9,39.9,33.9Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M39.9,26.6v7.3c-.6,0-2.8.2-2.8.2V27A8.42,8.42,0,0,1,39.9,26.6Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M39.9,23.9v2.7a15.32,15.32,0,0,0-2.8.3,22.28,22.28,0,0,1,1.3-3h1.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M39.9,20.8V24H38.6a9.92,9.92,0,0,0,.4-1.9C39.3,20.9,39.9,20.8,39.9,20.8Z" transform="translate(-30.6 -3.6)"/>
          </g>
          <g id="shuttle-2" data-name="shuttle">
            <path class="cls-34" d="M69.8,78.9c.1,1.3.2,2.9,0,3,0,0-1,.3-2.3.6V80.7c0-.7-.8-.8-.8-.8H59c-1.2,0-1.3.9-1.3.9v3.8a9,9,0,0,1-1.5.3l.1-7.2-.6-1.5h-.8V44.5c.1.2,1.8,10.8,2.6,15a4.33,4.33,0,0,0,.1.5c.1.6.2,1.1.2,1.2v.3c.4,2.1,1.1,5.3,1.4,5.5.4.4,8,7.9,8.6,8.6S69.8,78.9,69.8,78.9Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M69.7,77.5a9.51,9.51,0,0,0,.1,1.4s-1.5-2.5-2-3.3-8.2-8.3-8.6-8.6-.9-3.4-1.4-5.5A31.14,31.14,0,0,0,59,65.4c.4.5,8.9,8.8,9.2,9.3A9.59,9.59,0,0,1,69.7,77.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M67.5,80.7v1.8c-2.8.6-7.4,1.6-9.8,2.1V80.8s0-.9,1.3-.9h7.7S67.5,80,67.5,80.7Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M55.7,76.2l.6,1.5-.1,7.2h-.1s.3,1.9.1,1.9H54.9V85.4l-2.7-.1V83.1h-.8v-7H55l.7.1Z" transform="translate(-30.6 -3.6)"/>
            <polygon class="cls-34" points="24.3 40.9 24.3 72.6 20.7 72.6 19.6 72.6 19.6 40.9 24.3 40.9"/>
            <path class="cls-34" d="M54.9,44.5H50.2V38.1h1.2v-1H50.2V29.6s2,.3,3,3.1a33.82,33.82,0,0,1,1.5,6.1C54.9,39.9,54.8,44.2,54.9,44.5Zm-.4-4.3-.2-1.3-.4.3.3,1Zm-1-1.3.4-.5-1.7-1.2v.9Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M54.3,87.9s.7,1.2.6,1.7-1,.9-1.8.9a4.64,4.64,0,0,1-1.8-.7H52V87.4h-.6V85.6h.5v-.4h.2l2.7.1v2.5h-.5Z" transform="translate(-30.6 -3.6)"/>
            <polygon class="cls-34" points="23.7 35.3 23.9 36.6 23.6 36.6 23.3 35.7 23.7 35.3"/>
            <polygon class="cls-34" points="23.3 34.9 22.9 35.3 21.5 34.6 21.5 33.7 23.3 34.9"/>
            <polygon class="cls-34" points="21.5 79.6 21.5 81.8 21.2 81.8 21.2 82.1 20.8 82.1 20.8 83.9 21.4 83.9 21.4 86.3 20.8 86.3 20.5 86.3 20.5 89.8 19.6 89.8 19.6 72.6 20.7 72.6 20.7 79.6 21.5 79.6"/>
            <rect class="cls-34" x="19.6" y="33.5" width="1.2" height="1"/>
            <polygon class="cls-34" points="19.6 72.6 19.6 89.8 18.8 89.8 18.8 86.3 18.5 86.3 17.9 86.3 17.9 83.9 18.5 83.9 18.5 82.1 18.1 82.1 18.1 81.8 17.8 81.8 17.8 79.6 18.6 79.6 18.6 72.6 19.6 72.6"/>
            <polygon class="cls-34" points="19.6 40.9 19.6 72.6 18.6 72.6 15 72.6 15 40.9 19.6 40.9"/>
            <path class="cls-34" d="M50.2,38.1v6.4H45.5c.1-.3,0-4.6.2-5.6a26.81,26.81,0,0,1,1.5-6.1c1-2.8,3-3.1,3-3.1v7.5H49v1h1.2Zm-1.8.1v-.9l-1.7,1.2.4.5Zm-2.1,2,.3-1-.4-.3L46,40.2Z" transform="translate(-30.6 -3.6)"/>
            <rect class="cls-34" x="18.5" y="33.5" width="1.2" height="1"/>
            <path class="cls-34" d="M49.2,76.2v7h-.8v2.2l-2.7.1v1.4H44.4c-.2,0,.1-1.9.1-1.9h-.1l-.1-7.2.6-1.5h.8l3.5-.1Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M48.5,89.9h.6a4,4,0,0,1-1.8.7,2.13,2.13,0,0,1-1.8-.9c-.1-.5.6-1.7.6-1.7h-.5V85.5l2.7-.1h.2v.4H49v1.8h-.6l.1,2.3Z" transform="translate(-30.6 -3.6)"/>
            <polygon class="cls-34" points="17.8 33.7 17.8 34.6 16.4 35.3 16 34.9 17.8 33.7"/>
            <polygon class="cls-34" points="16 35.7 15.7 36.6 15.4 36.6 15.6 35.3 16 35.7"/>
            <path class="cls-34" d="M45.6,44.5V76.2h-.8l-.6,1.5.1,7.2c-.2,0-.8-.2-1.5-.3V80.8s0-.9-1.3-.9H33.7a.81.81,0,0,0-.7.8v1.8a9.51,9.51,0,0,1-2.3-.6,27.75,27.75,0,0,1,0-3s1.5-2.5,2-3.3,8.2-8.3,8.6-8.6.9-3.4,1.4-5.5v-.3c0-.1.1-.5.2-1V60a1.75,1.75,0,0,0,.1-.5C43.8,55.2,45.5,44.7,45.6,44.5Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M42.8,80.8v3.8c-2.4-.5-7-1.5-9.8-2.1V80.7c0-.7.8-.8.8-.8h7.8C42.9,79.9,42.8,80.8,42.8,80.8Z" transform="translate(-30.6 -3.6)"/>
            <path class="cls-34" d="M42.6,61.5c-.4,2.1-1.1,5.3-1.4,5.5-.4.4-8,7.9-8.6,8.6s-2,3.3-2,3.3c0-.7.1-1.2.1-1.4a12.21,12.21,0,0,1,1.4-2.8c.3-.5,8.8-8.8,9.2-9.3A16.17,16.17,0,0,0,42.6,61.5Z" transform="translate(-30.6 -3.6)"/>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
