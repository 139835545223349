<div class="services-section" #container>
  <h2 class="services-header">SERVICES</h2>
  <div class="services-content">

    <!-- DEVELOPER -->
    <div 
      class="svc-dev service svc-card" 
      [ngClass]="{'closed-card': card['dev'].isActive, invisible: !card['dev'].show, slideIn: card['dev'].show}"
      (click)="toggleCard('dev')">
      <div class="service-item-header">
        <h3>DEVELOPER</h3>
      </div>
      <div class="svc-items">
        <i class="fa fa-desktop"></i>
        <h4>Design & Development</h4>
        <i class="fa fa-pie-chart"></i>
        <h4>Business Solutions</h4>
        <i class="fa fa-gamepad"></i>
        <h4>Games and Entertainment</h4>
      </div>
    </div>
    
    <div 
      class="svc-dev service svc-info" 
      [ngClass]="{'closed-card': !card['dev'].isActive, invisible: card['dev'].new}"
      (click)="toggleCard('dev')">
      <div class="service-item-header">
          <h3>DEVELOPER</h3>
      </div>
      <p>Using innovative technologies, I will help you achieve your goals by developing your business, with a strong focus on scalability, user experience, technological solutions, and innovations. I will help to secure and expand your customer base with high conversion rates.</p>
    </div>

    <!-- MARKETING -->
    <div 
      class="svc-design service svc-card"
      [ngClass]="{'closed-card': card['design'].isActive, invisible: !card['design'].show, slideIn: card['design'].show}"
      (click)="toggleCard('design')">
      <div class="service-item-header">
        <h3>MARKETING</h3>
      </div>
      <div class="svc-items">
        <i class="fa fa-tablet"></i>
        <h4>Digital Marketing</h4>
        <i class="fa fa-eye"></i>
        <h4>Increase in Flow</h4>
        <i class="fa fa-pencil"></i>
        <h4>Consultancy and Analysis</h4>
      </div>
    </div>

    <div 
      class="svc-design service svc-info"
      [ngClass]="{'closed-card': !card['design'].isActive, invisible: card['design'].new}"
      (click)="toggleCard('design')">
      <div class="service-item-header">
          <h3>MARKETING</h3>
      </div>
      <p>With creativity and advanced tools, I will help you achieve your goals by creating the design and experience that works across all platforms, promoting your business presence and digital identity. Don't have a logo yet? No problem! I will help you with your branding too!</p>
    </div>

    <!-- ENTERPRENEUR -->
    <div class="svc-producer service svc-card"
      [ngClass]="{'closed-card': card['producer'].isActive, invisible: !card['producer'].show, slideIn: card['producer'].show}"
      (click)="toggleCard('producer')">
      <div class="service-item-header">
        <h3>ENTERPRENEUR</h3>
      </div>
      <div class="svc-items">
        <i class="fa fa-handshake-o"></i>
        <h4>Business Partnerships</h4>
        <i class="fa fa-bar-chart"></i>
        <h4>Scaling</h4>
        <i class="fa fa-play"></i>
        <h4>Digital Content Production</h4>
      </div>
    </div>

    <div 
      class="svc-producer service svc-info" 
      [ngClass]="{'closed-card': !card['producer'].isActive, invisible: card['producer'].new}"
      (click)="toggleCard('producer')">
      <div class="service-item-header">
        <h3>ENTERPRENEUR</h3>
        </div>
        <p>With a decade of experience in the technology field and a 100% success rate in partnerships and ventures, I will help you achieve your goals by promoting your brand, while assisting in the planning and development of your business. Shall we create a successful partnership?</p>
      </div>
  </div>
</div>
