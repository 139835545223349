<!-- <footer> -->
<footer class="sticky-footer">
  <div class="grid-footer">
    <div class="box1">
        <p>SEND ME AN EMAIL</p>
        <a
          href="mailto:richard.wmbv@gmail.com?subject=Let's build something awesome!" 
          target="_top"
          class="contact">richard.wmbv@gmail.com</a>
      </div>
      <div class="box2">
        <p>FOLLOW ME HERE!</p>
        <div class="follow-me">
          <a
            target="_blank" 
            href="https://www.facebook.com/DotAchieve-100866838258610"><i class="fa fa-facebook-square"></i></a>
          <a 
            target="_blank"
            href="https://github.com/rwmb"><i class="fa fa-github"></i></a>
          <a 
            target="_blank"
            href="https://www.linkedin.com/in/richardwmb/"><i class="fa fa-linkedin-square"></i></a>
        </div>
      </div>
      <div class="box3">
        <p>&copy; 2017 - {{getCopyrightYear()}} DOTACHIEVE. All rights reserved.</p>
      </div>
  </div>
</footer>