<div class="about-section" #container>
  <h2 class="about-header">A LITTLE BIT ABOUT ME</h2>
  <div class="picture" (click)="changePicture()">
      <img #aboutImage src="assets/images/about.jpg" alt="Richard William">
      <outline-profile #outline></outline-profile>
      <app-bunny #bunny></app-bunny>
      <app-insect #insect></app-insect>
      <app-pirate #pirate></app-pirate>
      <app-diver #diver></app-diver>
  </div>
  <div class="about" #aboutText [ngClass]="{fadeInUp: showText, invisible: !showText}">
    <p class="first-paragraph">Hello! My name is Richard William. I'm a Software Engineer and Entrepreneur with over 10 years of experience creating solutions and developing businesses. I started my journey in 2013, creating desktop applications to assist me in my administrative tasks. But I have been producing digital content since I was 14, and coding since I was 16. Later, I undertook a Computer Science degree with an emphasis on Game Development at the University of Vale do Rio dos Sinos, in Porto Alegre. It was at that moment that I discovered my passion for creating interactive and fun solutions to help companies and individuals achieve their goals. Since then, I've been breathing code, entertainment, and business management daily. I can't stop. And I don't want to.</p>
    <p class="second-paragraph">Today, I study and develop new technologies, strategically applying them to build and grow my own businesses and those of my business partners. You can count on me to develop your project!</p>
  </div>
</div>
