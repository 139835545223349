import { Component } from '@angular/core';
import { OutlineComponent } from '../outline.component';

@Component({
  selector: 'app-pirate',
  templateUrl: './pirate.html',
  styleUrls: ['./pirate.scss']
})
export class PirateComponent extends OutlineComponent {
  constructor() {
    super('.pirate-outline');
  }
}
