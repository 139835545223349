import { Component } from '@angular/core';
import { OutlineComponent } from '../outline.component';

@Component({
  selector: 'app-bunny',
  templateUrl: './bunny.html',
  styleUrls: ['./bunny.scss']
})
export class BunnyComponent extends OutlineComponent {
  constructor() {
    super('.bunny-outline');
  }
}
