<svg version="1.1" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g id="Layer_1">
</g>
<g id="Layer_2">
	<line class="diver-outline st0" x1="241.5" y1="187.5" x2="201.5" y2="220.5"/>
	<line class="diver-outline st0" x1="263.5" y1="189.5" x2="238.5" y2="217.5"/>
	<line class="diver-outline st0" x1="301.5" y1="189.5" x2="272.5" y2="212.5"/>
	<line class="diver-outline st0" x1="336.5" y1="198.5" x2="298.5" y2="222.5"/>
	<path class="diver-outline st1" d="M172.5,200.5c0,0,16-44,43-42s37,16,50,15s43-20,57-15s37,43,40,49s0,27,0,27s-34,17-53,11s-25-17-45-16
		s-28,16-43,17s-48-8-49-12S172.5,200.5,172.5,200.5z"/>
	<path class="diver-outline st2" d="M184.5,196.5c0,0,15-24,20-24s19-4,8,8s-20,32-26,26S184.5,196.5,184.5,196.5z"/>
	<path class="diver-outline st3" d="M289.5,288.5l69-3c0,0,13,0,17-10s4-209,4-209"/>
	<path class="diver-outline st4" d="M267.5,277.5h19c0,0,14,11,4,15s-28,3-34,0c0,0-10-10-2-12s25-2,26-1s8,6,4,7s-9,3-14,2s-14,1-9-2
		s103-1,110-16s0-199,0-199s-3-13,7-13s8,10,8,10v199c0,0,0,15-23,21s-90,4-90,4"/>
	<circle class="diver-outline st0 bubbles" cx="392" cy="43" r="5.5"/>
	<circle class="diver-outline st0 bubbles" cx="367" cy="23" r="6.5"/>
	<circle class="diver-outline st0 bubbles" cx="390" cy="20" r="2.5"/>
	<path class="diver-outline st5 fish" d="M155.5,67.5c0,0,25-34,26-20s6,63-6,49s-20-16-20-16s-25,36-50,23s-23-28-23-28s4-40,30-31
		S155.5,67.5,155.5,67.5z"/>
	<circle class="diver-outline st6 fish" cx="98" cy="63" r="6"/>
	<path class="diver-outline st6 fish" d="M85.6,86.5c0,0,15.4,2.5,20.4-5.5"/>
	<circle class="diver-outline st6 fish" cx="95" cy="65" r="2"/>
	<path class="diver-outline st6 fish" d="M123,90c0,0,8,15,14,20s-29,2-24-15"/>
	<line class="diver-outline st6 fish" x1="182.2" y1="55.9" x2="167" y2="64"/>
	<line class="diver-outline st6 fish" x1="183.2" y1="70" x2="167" y2="72"/>
	<line class="diver-outline st6 fish" x1="183.4" y1="85.8" x2="171" y2="81"/>
	<path class="diver-outline st6 fish" d="M137.7,98.5c0,0,1.3,5.5,10.3,6.5s-4.3-2.2-5.2-10.6"/>
</g>
</svg>
