import { Component } from '@angular/core';
import { OutlineComponent } from '../outline.component';

@Component({
  selector: 'app-diver',
  templateUrl: './diver.html',
  styleUrls: ['./diver.scss']
})
export class DiverComponent extends OutlineComponent {
  constructor() {
    super('.diver-outline');
  }
}
