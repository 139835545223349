import { drawFigure, eraseFigure } from '../../shared/util/svg-drawing-tool.util';
import { TweenLite } from 'gsap';
import { sleep } from '../../shared/util/util';

export class OutlineComponent {
  staggerPause = 0.3;

  constructor(private classSelector: string, private strokeOffset: number = 1100) {
  }

  public draw(drawDuration) {
    return drawFigure(this.classSelector, drawDuration, this.staggerPause);
  }

  public wait(waitToErase) {
    return sleep(waitToErase);
  }

  public hide() {
    TweenLite.to(this.classSelector, 0.1, {opacity: 0});
  }

  public show() {
    TweenLite.to(this.classSelector, 0.1, {opacity: 1});
  }

  public erase(eraseDuration) {
    return eraseFigure(this.classSelector, eraseDuration, this.strokeOffset);
  }

  public async play(drawDuration = 2, waitToErase = 4, eraseDuration = 1): Promise<any> {
    this.show();
    await this.draw(drawDuration);
    await this.wait(waitToErase * 1000);
    await this.erase(eraseDuration);
    this.hide();
  }
}