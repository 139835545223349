<svg version="1.1" id="Layer_1" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<path class="st0 bunny-outline ear" d="M299.5,134l20.5,9.5c0,0,3.5-31,17-40.5s41-18,45.5-5s8.5,45,8,56s26,28,40,22s16-25.5,11-39s-14.5-76-43-80.5
	s-62-8.5-75,9s-24,66-24,66"/>
<path class="st0 bunny-outline" d="M203.5,146.5l23-7.5l-34-107.5c0,0-13-19-27.5-11.5s-23.5,10-22.5,22S203.5,146.5,203.5,146.5z"/>
<polyline class="st1 bunny-outline nose" points="265.2,258.8 249.3,251 274.8,248.1 264.5,259.2 "/>
<path class="st2 bunny-outline nose" d="M313.5,248.1c7.7,3.1,16,26.4-14.8,26.4c-7,0-22.4-4.2-28.8-9.2"/>
<path class="st3 bunny-outline whisker1" d="M321,236c0,0,61-17.5,80.5,7"/>
<path class="st3 bunny-outline whisker1" d="M327.2,247.8c0,0,62.5-5.3,72,16.9"/>
<path class="st3 bunny-outline whisker2" d="M205.6,239.2c0,0-51-19.6-63-13.1"/>
<path class="st3 bunny-outline whisker2" d="M200.9,253.6c0,0-49.5-14.7-63-5.2"/>
<path class="st0 bunny-outline nose" d="M262.8,263.7c0.3,3.7-15.6,19.2-33.8,9.1c-14.9-8.3-20.3-15-10.3-24.7"/>
<polyline class="st4 bunny-outline" points="282.5,283.5 282.5,309.3 256,310.3 256.5,282 "/>
<line class="st4 bunny-outline" x1="269.3" y1="285.8" x2="269.3" y2="309.8"/>
</svg>
