import { Component } from '@angular/core';
import { OutlineComponent } from '../outline.component';

@Component({
  selector: 'app-insect',
  templateUrl: './insect.html',
  styleUrls: ['./insect.scss']
})
export class InsectComponent extends OutlineComponent {
  constructor() {
    super('.insect-outline', 400);
  }
}
