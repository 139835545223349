<svg version="1.1" id="Layer_1" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<style type="text/css">
</style>
<g id="Layer_1_1_">
	<path class="st0 outline-profile" d="M0.5,397.5l53-19c0,0,4-10,10-12s8,4,10,3s17-13,23-13s6-3,13-3s30.5,1.3,37.5-1.8s10.3-9.1,14.3-10.1
		s10.8-4.6,18.8-3.6s19.5,1.1,22.5-2.9s0.9-16.3-8.1-30.3l-19.1-45.4c-8-12-13-27-12-31s0.6-12.6-0.4-18.6c0-0.2-0.4-3.2-0.4-3.4
		c-0.6-3.5,4.2-3.1,7.5-1.7l-1.5-4.9c0,0-2.2-17.5-2.2-20.5s1-30,6-39s22-39,30-45s9.8-5.1,9.8-5.1l27-17c0,0,3.2-6.4,12.2-6.4
		c9,0,37,1.4,40,4.4s25,15,25,15l29,30c0,0,17,31,18,38s0,46,0,46l5-0.5c2.8-0.4,4.6,2.3,4.4,7l-0.7,16l-1.4,15.8
		c0,0-6.6,13.3-8.6,15.3c-1.5,1.5-0.1,13-4.5,27.2c-1.5,4.9-3.8,13.4-6.7,17.3c-9.9,13.5-7.5,16.2-7.1,18.4c0.1,0.6,0.2,1.3,0.4,1.9
		l1.1,4l3.8,7.3c0.1,0.4,0.2,0.7,0.3,1l2,4.4c0.3,0.7,0.8,1.2,1.5,1.5c4.5,2.3,23.1,11.5,28.4,12.4c6,1,18,9,18,9s7,9,16,11
		s27,10,27,10s8,5,12,6s19,2,19,2l13,6c0,0,6,5,10,6s15,14,15,14"/>
	<path class="st0 outline-profile" d="M192.5,189.5l10-14l33,2l12,9l-8,3C239.5,189.5,202.5,183.5,192.5,189.5z"/>
	<path class="st0 outline-profile" d="M288.5,182.5l4.2-11l20.6-1c11.6-0.6,19-1.8,24.5,8.4l5.7,10.6C343.5,189.5,317.5,174.5,288.5,182.5z"/>
	<path class="st0 outline-profile" d="M261.3,197c0,0-5.3,37.5-8.2,44.2c-2.4,5.6-2,14.8-2,14.8s5.8,4.5,9.8,6.5s12.7,1.8,12.7-1.2s2.8-9.8,11.8-6.8
		c9,3,2.2-9.7,2.2-9.7"/>
	<path class="st0 outline-profile" d="M247.5,237.5l-6,14c0,0,0,5,6,5"/>
	<path class="st0 outline-profile" d="M225.5,277.5c0,0,19.1,1.8,33.7,1.9c4.2,0,5.5,1,8.4,0.6c1.8-0.3,4.3-1.6,6.4-1.9c13.4-2.1,36.5-5.7,39.9-4.8
		c4,1-7.8,14.3-7.8,14.3s-24.5,7.4-36.5,8.4s-42.2-10.4-44.2-15.4S225.5,277.5,225.5,277.5z"/>
	<path class="st0 outline-profile" d="M198.2,311.6c0,0,28.3,44.9,75.3,45.9s69-44,69-44l2.7-5.9"/>
	<path class="st0 outline-profile" d="M294.5,204.5c0,0,11-6,16-6s20,6,20,6s-11,4-18,4S294.5,204.5,294.5,204.5z"/>
	<path class="st0 outline-profile" d="M365.8,199.3l-2.4,35.4l-2.8-23.5c0,0-4.5-32.7-5.7-39s-18-51-24.7-55.2s-8.8-11-18-14s-12.5-1.4-12.5-1.4
		l0.7-3.6c0,0-25.8,4.5-34.7,4.3c-8.8-0.2-23.8,1-28.8,2s-18.2,2.2-21.2,5.2s-23.7,23-25.3,24.8c-1.7,1.8-6.8,22.2-6.8,22.2
		l-5.3,22.3l-4.2,58.7l-3.9-34.9"/>
	<path class="st0 outline-profile" d="M208.5,208.6c0,0,11-6,16-6s20,6,20,6s-11,4-18,4S208.5,208.6,208.5,208.6z"/>
</g>
<ellipse class="outline-profile" cx="311.7" cy="203.7" rx="5" ry="4.5"/>
<ellipse class="outline-profile" cx="224.5" cy="208.1" rx="5" ry="4.5"/>
<path class="st1 outline-profile" d="M225.5,277.5"/>
<path class="st0 outline-profile" d="M348.6,326.6c0,0-7.7,17.1-12.2,21.6s-19.1,15.9-22.4,25.3c-3.2,9-9.9,19.3-9.9,19.3s-6.8,0.3-9.5,5.5
	s-41.8,68.1-41.8,68.1l-14.4-33.7c0,0-17.8-29.8-18.7-35.2c-0.7-4.2-4.5-16.2-6.2-23c-0.5-1.8-0.8-3.3-0.8-4.1
	c-0.3-4.2,0.7-10-3.3-17.7s-6.8-17.5-6.8-17.5"/>
<path class="st0 outline-profile" d="M213.7,374.5c0,0,22.9,14,36.5,15.7c9,1.1,26.3,2.5,41,0.1c12.4-2,14.2-4.9,22.9-16.8"/>
<path class="st0 outline-profile" d="M182.5,338.3c0,0-3,10.3-5.4,14.8c-2.3,4.5-11,16.3-12.5,20.1s11.3,9.2,11.3,9.2s29.7,28.1,39.7,46.1
	s13.7,43.7,17.7,55.7l8,28.2c0,0,2.7,7.9,3.7,12.6c1,4.7,3.5,0,3.5,0s2.2,0.7,2-5.3s34.7-74.7,39.3-79.8
	c4.7-5.2,38.8-39.2,44.7-44.3c5.8-5.2,12.5-13.2,14.1-18.5s-4.4-5.7-6.8-11c-2.3-5.3,2.2-13.3,2.2-13.3l7.2-18"/>
<line class="st0 outline-profile" x1="252.9" y1="466.4" x2="237.6" y2="499.5"/>
<path class="st0 outline-profile" d="M289.1,407.6c0,0-20.6,2.6-32,0s-29.7-7-38.3-14.7"/>
</svg>
