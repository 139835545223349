<svg version="1.1" id="Layer_1" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
	<path class="st2 insect-outline" d="M309,135.2c1.9-3.5,3.9-7,5.9-10.5c2-3.5,4.1-6.9,6.2-10.3c4.2-6.8,8.5-13.6,12.9-20.3c4.5-6.7,9.1-13.3,13.8-19.8
		c4.8-6.5,9.8-12.8,15-19c5.2-6.2,10.6-12.4,16.4-18.2c5.9-5.8,12.3-11.2,20-15.2c3.8-2,7.9-3.7,12.4-4.7c4.5-0.9,9.4-1.1,14.1,0.4
		c4.6,1.6,8.5,4.7,10.9,8.4c2.5,3.7,4,7.6,4.8,11.7c0.1,0.3-0.1,0.6-0.4,0.7c-0.2,0-0.4,0-0.6-0.2l0,0c-2.3-3.3-4.7-6.4-7.5-8.8
		c-2.8-2.4-5.9-3.8-9-4.2c-3.2-0.3-6.4,0.2-9.6,1.4c-3.2,1.2-6.4,2.9-9.5,4.9c-6.2,4-12.2,8.8-18.2,13.7c-6,5-11.7,10.3-17.2,16
		c-5.5,5.7-10.7,11.6-15.8,17.7c-5.2,6-10.2,12.2-15.1,18.5c-9.9,12.5-19.4,25.4-28.5,38.4l0,0c-0.2,0.2-0.5,0.3-0.8,0.1
		C309,135.8,308.9,135.5,309,135.2z"/>
</g>
<g>
	<path class="st2 insect-outline" d="M215.5,135.8c-8.8-12.8-17.9-25.4-27.4-37.6c-4.7-6.1-9.5-12.2-14.4-18.2c-4.9-6-9.8-11.9-15-17.5
		c-5.1-5.7-10.6-11.1-16.2-16.2c-1.4-1.3-2.8-2.5-4.2-3.8c-1.4-1.3-2.8-2.5-4.2-3.7c-2.8-2.4-5.6-4.8-8.5-7.1
		c-5.7-4.4-11.9-8.3-18.1-9.2c-1.5-0.1-3-0.1-4.5,0.2c-1.5,0.4-3,0.9-4.4,1.9c-2.9,1.8-5.4,4.8-7.6,7.9l0,0
		c-0.2,0.3-0.5,0.3-0.8,0.1C90,32.7,90,32.5,90,32.3c0.8-3.9,2.4-7.8,5.3-11.2c1.4-1.7,3.3-3.1,5.4-4.3c0.5-0.2,1.1-0.5,1.6-0.7
		c0.5-0.3,1.1-0.4,1.7-0.5c1.2-0.4,2.3-0.5,3.5-0.7c4.7-0.4,9.2,0.6,13.3,2.1c4.1,1.5,7.7,3.6,11.2,5.8c6.9,4.5,12.9,10,18.3,15.8
		c5.4,5.9,10.3,12,15.2,18.2c4.9,6.1,9.6,12.4,14.1,18.8c4.5,6.4,8.8,12.9,13.1,19.4c4.2,6.6,8.3,13.2,12.3,19.9
		c2,3.3,4,6.7,5.9,10.1c1.9,3.4,3.8,6.8,5.7,10.2c0.1,0.3,0,0.6-0.2,0.7C216,136.1,215.7,136,215.5,135.8L215.5,135.8z"/>
</g>
<path class="st0 insect-outline" d="M346.5,200.8c0,23.3-13.5,50.6-30.1,50.6s-30.1-27.2-30.1-50.6s13.5-29.4,30.1-29.4S346.5,177.4,346.5,200.8z"
	/>
<path class="st0 insect-outline" d="M253,201.1c0,26.3-15.5,47.2-33,47.2s-30.3-20.9-30.3-47.2s12.8-29.8,30.3-29.8S253,174.8,253,201.1z"/>
<g>
	<path class="st2 insect-outline" d="M252.3,284.2c-3.1,3.4-6.2,6.8-9,10.3c-2.9,3.5-5.7,7-8.3,10.5c-1.3,1.8-2.3,3.7-3.3,5.6c-1,1.9-2.1,3.6-3.5,5.1
		c-1.4,1.5-2.8,3-4.3,4.5c-1.5,1.5-3.1,3-5.1,5l0,0c-0.3,0.3-0.8,0.3-1,0c0,0-0.1-0.1-0.1-0.2c-1.5-2.5-1.8-5.4-1.6-8.1
		c0.2-2.7,0.9-5.3,2-7.6c1.1-2.3,2.6-4.4,4.4-6.1c1.8-1.7,3.8-3.2,5.6-4.8c3.6-3.2,7.4-6,11.4-8.5c4-2.5,8-4.8,12.3-6.7
		c0.3-0.1,0.7,0,0.8,0.3C252.6,283.7,252.5,284,252.3,284.2L252.3,284.2z"/>
</g>
<g>
	<path class="st2 insect-outline" d="M258.5,284.9c-1.2,4.9-2.4,9.9-3.5,14.8c-0.6,2.4-1.1,4.9-1.6,7.3c-0.5,2.4-1.1,4.9-1.5,7.3c-0.5,2.4-0.6,4.9-0.9,7.3
		c-0.2,2.4-0.8,4.8-1.5,7l-1.3,3.4c-0.4,1.1-1,2.3-1.5,3.4c-0.5,1.1-1.1,2.3-1.7,3.4c-0.6,1.2-1.2,2.4-2.1,3.7l0,0.1
		c-0.2,0.3-0.7,0.4-1,0.2c-0.1,0-0.1-0.1-0.2-0.2c-0.9-1.2-1.6-2.5-2.2-3.9c-0.5-1.3-0.9-2.7-1.2-4.1l-0.2-1l-0.1-1
		c-0.1-0.7-0.1-1.4-0.2-2.1c-0.1-0.7-0.1-1.4-0.1-2.1l0.1-2c0.1-1.3,0.2-2.7,0.6-4c0.3-1.3,0.7-2.5,1.2-3.8c1.1-2.4,2.2-4.8,3.2-7.2
		c1-2.4,2.1-4.7,3.2-7.1c1.2-2.3,2.3-4.6,3.6-6.8c2.5-4.5,5.1-8.9,8-13.1c0.2-0.3,0.6-0.3,0.8-0.2
		C258.5,284.4,258.6,284.7,258.5,284.9L258.5,284.9z"/>
</g>
<g>
	<path class="st2 insect-outline" d="M269.8,285.4c0.6,6.1,1,12.2,1.4,18.3l0.5,9.1l0.4,9.1c0.1,3,0.5,6.1,0.8,9.1c0.3,3,0.2,6-0.2,9c-0.9,6-2.5,11.9-5.2,17.9
		l-0.1,0.2c-0.1,0.3-0.5,0.5-0.9,0.3c-0.1,0-0.2-0.1-0.2-0.2c-0.5-0.7-1.1-1.5-1.5-2.3c-0.4-0.8-0.7-1.5-1.1-2.3
		c-0.6-1.6-1.1-3.1-1.5-4.6c-0.8-3.1-1.3-6.2-1.6-9.2c-0.3-3.1-0.2-6.1,0.3-9.2c0.5-3,1.1-6.1,1.5-9.1l1.2-9.1l1.4-9
		c1-6,2.1-12,3.4-18c0.1-0.3,0.4-0.5,0.7-0.5C269.6,284.9,269.8,285.1,269.8,285.4z"/>
</g>
<g>
	<path class="st2 insect-outline" d="M279.9,285.1c3.1,5.1,5.9,10.3,8.7,15.6l4,8c1.3,2.7,2.5,5.4,3.8,8.1c1.2,2.7,2.7,5.4,3.9,8.1c1.3,2.7,2.2,5.6,2.7,8.6
		c0.5,3,0.8,6.1,0.6,9.3c0,0.8-0.1,1.6-0.2,2.4c-0.1,0.8-0.2,1.6-0.4,2.4c-0.2,0.8-0.4,1.6-0.7,2.5c-0.2,0.4-0.3,0.8-0.5,1.2
		c-0.2,0.4-0.4,0.8-0.6,1.2c-0.2,0.3-0.6,0.4-0.9,0.2c-0.1-0.1-0.2-0.1-0.2-0.2l0,0c-0.9-1.4-1.7-2.7-2.4-3.9
		c-0.4-0.6-0.8-1.3-1.2-1.9c-0.4-0.6-0.8-1.3-1.1-1.9c-1.4-2.5-2.7-5.1-3.9-7.8c-1.1-2.6-2-5.4-2.5-8.3c-0.5-2.9-1-5.8-1.7-8.6
		c-0.7-2.8-1.4-5.7-2.2-8.5l-2.1-8.6c-1.4-5.7-2.8-11.5-4.2-17.3c-0.1-0.3,0.1-0.7,0.5-0.7C279.5,284.8,279.8,284.9,279.9,285.1z"/>
</g>
<g>
	<path class="st2 insect-outline" d="M287.9,283.3c4.5,3.4,8.8,7.2,13,10.9c2.1,1.9,4.2,3.9,6.2,5.8c2,2,4,4.1,5.9,6.2c1.9,2.1,4,4.1,6,6.2
		c2,2.1,3.6,4.6,4.9,7.3c1.2,2.7,2.2,5.6,2.7,8.8c0.1,0.8,0.2,1.6,0.2,2.4c0,0.9,0,1.7-0.1,2.5c-0.1,0.9-0.2,1.7-0.5,2.6
		c-0.2,0.4-0.2,0.8-0.5,1.2c-0.2,0.4-0.4,0.8-0.7,1.1c-0.2,0.3-0.6,0.4-0.9,0.1c-0.1-0.1-0.1-0.1-0.2-0.2l-0.1-0.1
		c-0.8-1.4-1.6-2.4-2.5-3.4c-0.4-0.5-0.9-1-1.3-1.5c-0.5-0.5-0.9-1-1.3-1.5c-1.8-1.9-3.5-3.9-5.2-6c-1.7-2-3.1-4.3-4.4-6.7
		c-1.2-2.4-2.4-4.9-3.9-7.2c-1.5-2.3-3-4.7-4.5-7c-1.5-2.3-3-4.7-4.6-7c-3.1-4.7-6.2-9.3-9.3-14l0,0c-0.2-0.3-0.1-0.7,0.2-0.8
		C287.5,283.1,287.7,283.1,287.9,283.3z"/>
</g>
<path class="st1 insect-outline" d="M238.7,281.3c0,0,24.5,5.2,35.2,4.7c10.7-0.5,32.3-8.2,32.3-8.2"/>
</svg>
