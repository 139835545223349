import { Component, AfterViewInit, ViewEncapsulation, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { TweenLite } from 'gsap';

import { PirateComponent } from './svg/pirate/pirate.component';
import { BunnyComponent } from './svg/bunny/bunny.component';
import { DiverComponent } from './svg/diver/diver.component';
import { InsectComponent } from './svg/insect/insect.component';
import { PageControllerService } from '../shared/services/page-controller.service';
import { Subscription } from 'rxjs';
import { OutlineComponent } from './svg/outline.component';
import { OutlineProfileComponent } from './svg/outline-profile/outline-profile.component';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AboutComponent implements OnDestroy, AfterViewInit {
  @ViewChild('container') container: ElementRef;
  @ViewChild('aboutText') aboutText: ElementRef;
  @ViewChild('aboutImage') image: ElementRef;
  @ViewChild('outline') outline: OutlineProfileComponent;
  @ViewChild('pirate') pirate: PirateComponent;
  @ViewChild('bunny') bunny: BunnyComponent;
  @ViewChild('diver') diver: DiverComponent;
  @ViewChild('insect') insect: InsectComponent;
  private images: OutlineComponent[];
  private imageBeingShown: boolean = true;
  private subscriptions: Subscription[] = [];
  showText = false;

  constructor(private pageCtrlService: PageControllerService) { }

  ngAfterViewInit() {
    this.initializeImages();
    const subscription = this.pageCtrlService.screenLoad.subscribe(() => {
      this.setDrawingCallback();
      subscription.unsubscribe();
    });
    this.subscriptions.push(subscription);
  }

  initializeImages() {
    TweenLite.to(this.image.nativeElement, 0.1, {opacity: 0});
    this.outline.hide();
    this.images = [this.pirate, this.bunny, this.diver, this.insect];
  }

  setDrawingCallback() {
    const subscription = this.pageCtrlService.onScrollDown('about', -100).subscribe(() => {
      this.showPicture();
      this.showText = true;
      subscription.unsubscribe();
    });
    this.subscriptions.push(subscription);
  }

  async changePicture() {
    if (this.imageBeingShown) {
      return;
    }

    this.imageBeingShown = true;
    const index = Math.floor(Math.random() * this.images.length);
    this.showSelectedPicture(this.images[index]);
  }

  // Show the first picture.
  async showPicture() {
    this.outline.show();
    await this.outline.draw(1);
    TweenLite.fromTo(this.image.nativeElement, 0.5, {opacity: 0}, {opacity: 1, delay: 0});
    this.outline.hide();
    this.imageBeingShown = false;
  }

  // Show the selected picture passed in as a parameter.
  async showSelectedPicture(outlineComponent: OutlineComponent) {
    this.imageBeingShown = true;
    await outlineComponent.play(1, 2, 1);
    this.imageBeingShown = false;
  }

  getAge() {
    const today = new Date().getTime();
    const birthday = new Date('02/08/1994').getTime();
    const age = new Date(today - birthday).getUTCFullYear();
    return (age - 1970);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
