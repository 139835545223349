<svg version="1.1" id="Layer_1" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<path class="pirate-outline st0" d="M363,202h-16c0,0-14.5-24.5-32-24.5S285,188,285.5,202s7.5,24.5,26,23s28-10,26-20s-10-21-22.5-20.5
	s-21,1.5-21,8.5s-4.5,14,2,18.5s5,8.5,14,6s12,2.5,16.5-5s5-12,1.5-16.5s-14-9-16-7.5s-7,1-9.5,3s-4,0-3,6s4.5,9.5,4.5,9.5l4.5,3.5
	l9,1l6.7-5V198l-4.6-2.4l-5.4-1.8l-4.9-0.5l-4,2.5l0.9,3.8l4,1.7l2.3,2.3l3.9-0.4l1.5-1.9l-0.6-2.3l-4.5-1.8c0,0-58-24.5-71.5-28
	s-33-1.8-43.5-5.3S171,151.3,171,151.3"/>
<path class="pirate-outline st0" d="M325.7,213.7c0,0,14.5,20.3,17.8,23.3s15.3,20.8,21.8,20.8"/>
<g>
	<path class="pirate-outline st5" d="M268.3,273.3c-3.4,0.4-6.8,0.6-10.1,0.7c-3.4,0.1-6.7,0.2-10.1,0.2c-6.7,0-13.4-0.2-20.1-0.5h-0.3l-1-0.3l-0.7-0.2
		l-0.8-0.2c-0.5-0.1-1.1-0.3-1.6-0.3c-1.1-0.2-2.1-0.1-2.8,0.1s-1.1,0.4-1.5,0.9c-0.4,0.4-0.7,1.1-1,2.1c-0.2,1-0.4,2.1-0.4,3.3
		c-0.1,1.4-0.1,2.9-0.2,4.4c-0.1,1.5-0.2,2.9-0.3,4.3s-0.3,2.9-0.4,4.3c-0.7,5.7-1.6,11.4-2.7,17c-1.2,5.6-2.5,11.2-4.3,16.7
		c-0.4-5.8-0.4-11.5-0.4-17.2s0.2-11.4,0.3-17c0.1-2.8,0.1-5.6,0.1-8.4c0-1.4,0-2.7,0-4.2c0-1.6,0.1-3.4,0.6-5.2
		c0.4-1.8,1.2-3.8,2.8-5.5c0.8-0.9,1.7-1.6,2.7-2.1c1-0.6,2.1-0.9,3.1-1.1c2.1-0.4,4-0.3,5.7,0c0.9,0.2,1.7,0.4,2.5,0.6
		c0.4,0.1,0.8,0.3,1.2,0.4c0.4,0.2,0.8,0.3,1.3,0.6l-1.3-0.3c6.6,0.8,13.3,1.7,19.9,2.8c3.3,0.6,6.6,1.2,9.9,1.9
		C261.8,271.7,265.1,272.4,268.3,273.3z"/>
</g>
<g>
	<path class="pirate-outline st5" d="M272.7,273.3c3.5-1,6.9-1.8,10.4-2.6c3.5-0.8,7-1.5,10.5-2.1c3.5-0.7,7-1.2,10.5-1.8c3.5-0.6,7-1.1,10.5-1.6l-1.8,0.8
		c0.5-0.4,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.5,1.2-0.7c0.8-0.5,1.7-0.9,2.7-1.1c1-0.3,2.1-0.4,3.4-0.3c1.2,0.1,2.5,0.6,3.5,1.2
		c1,0.7,1.8,1.5,2.5,2.3c0.6,0.8,1.1,1.7,1.4,2.5c0.7,1.7,1.1,3.3,1.5,4.8c0.3,1.6,0.5,3.1,0.6,4.6l0.1,2.2l0.1,2.1l0.4,8.5
		c0.2,5.7,0.4,11.4,0.5,17.1s0.1,11.5-0.2,17.2c-1.6-5.6-2.8-11.2-3.8-16.8c-1.1-5.6-1.9-11.3-2.6-17c-0.4-2.8-0.7-5.7-1-8.6
		l-0.2-2.1l-0.2-2.1c-0.1-1.3-0.3-2.6-0.6-3.9c-0.3-1.2-0.6-2.4-1-3.3c-0.2-0.5-0.4-0.9-0.7-1.2c-0.2-0.3-0.4-0.5-0.6-0.6
		s-0.3-0.2-0.5-0.2s-0.5,0-0.9,0.1s-0.8,0.2-1.2,0.5c-0.2,0.1-0.4,0.2-0.7,0.4c-0.2,0.1-0.5,0.3-0.5,0.4l-1.3,0.8h-0.5
		c-3.5,0.3-7.1,0.6-10.6,0.8c-3.5,0.2-7.1,0.4-10.7,0.6c-3.6,0.1-7.1,0.2-10.7,0.3C279.8,273.5,276.3,273.5,272.7,273.3z"/>
</g>
<g>
	<path class="pirate-outline st5" d="M279.7,311.2L268.3,365l-6.6-54.6c-0.6-5,3-9.5,8-10.2c5-0.6,9.5,3,10.2,8C279.9,309.2,279.9,310.2,279.7,311.2z"/>
</g>
<path class="pirate-outline st1" d="M66.5,175c0,0,91.1-44,195.4-54c97-2,207.1,56,207.1,56S374.2,17.5,268.3,16.5S66.5,175,66.5,175z"/>
<g>
	<path class="pirate-outline st5" d="M221.7,63.3c7,1.3,13.9,3,20.9,4.6c6.9,1.6,13.7,3.4,20.5,5.4c3.4,1,6.9,1.6,10.4,2.4s6.7,2.1,9.8,3.9
		c3.1,1.8,6,3.9,8.8,6.4s5.4,5.3,7.7,9c0.3,0.5,0.2,1.2-0.4,1.5c-0.1,0.1-0.2,0.1-0.3,0.1c-4.2,1.1-8.1,1.2-11.8,1.1
		c-3.7-0.2-7.3-0.7-10.7-1.5c-3.5-0.8-6.7-2.2-9.7-4c-3-1.9-6-3.9-9.1-5.5c-6.2-3.3-12.4-6.8-18.5-10.4c-6.1-3.7-12.2-7.3-18.1-11.3
		c-0.4-0.3-0.5-0.9-0.3-1.3C220.9,63.4,221.3,63.2,221.7,63.3z"/>
</g>
<g>
	<path class="pirate-outline st5" d="M299.6,64.8c-6.1,3.7-12.2,7.2-18.4,10.7c-6.2,3.5-12.4,6.8-18.7,10c-3.1,1.6-6.1,3.5-9.2,5.3s-6.3,3.1-9.7,4
		c-3.4,0.9-6.9,1.6-10.6,1.9c-3.7,0.3-7.4,0.4-11.5-0.3c-0.5-0.1-0.8-0.5-0.7-1c0-0.1,0.1-0.3,0.2-0.4c2.4-3.3,5.2-5.9,8-8.2
		c2.9-2.3,5.8-4.3,8.9-6c3.1-1.7,6.3-3,9.8-3.9c3.4-0.9,6.9-1.6,10.3-2.6c6.7-2.1,13.5-4,20.3-5.8s13.7-3.6,20.6-5.1
		c0.4-0.1,0.8,0.2,0.9,0.6C300,64.3,299.9,64.7,299.6,64.8z"/>
</g>
<path class="pirate-outline st2" d="M242,59.1c0,0,8,7.3,16.5,6.7s21.8-2.7,24.5-6.7s4-17.7-2.7-21s-23.7-5-27.3-3.3s-15.3,5.3-14.3,10
	S242,59.1,242,59.1z"/>
<path class="pirate-outline st3" d="M251.7,43.8c-2.3,1-5,2.3-3.3,4s3.3,2.7,6,2.2s5.3-0.5,4.2-3S251.7,43.8,251.7,43.8"/>
<path class="pirate-outline st4" d="M275,43.8c0,0-5.3,1.7-6,3.2s-0.7,3.2,3,4.5s5.3,0,6.7-1.3C280,48.8,278,45.1,275,43.8z"/>
<polyline class="pirate-outline st2" points="272.5,62.7 272.2,64.5 273,66.4 270.9,66.4 269.1,65.9 269.5,64.3 268.3,62.7 266.6,63.7 266.9,65.2 
	267.7,67.1 264.4,67.1 264.4,65.3 264.1,63.3 262.2,63.3 262.2,66.6 260.3,66.6 260.3,63.3 257.9,63.6 257.5,67.1 255.3,66.6 
	254.8,62.1 "/>
</svg>
